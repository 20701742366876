<template>
	<q-page>
		<div class="h-full">
			<div class="q-pa-md row">
				<div v-for="j in reversedItems" :key="j">
					<q-card :style="{color: j.colors.color,'background-color': j.colors.bgColor}"
						class="col p-lr my-card opacity-md no-select">
						<div class="flex flex-justify w-full m-tb-xs">
							<q-icon name="edit" size="md" />
							<span v-if="j.orign">书面备注</span>
							<span v-else>已保存的回答</span>
							<q-checkbox dense size="md" v-model="j.isSelected" color="teal" />
						</div>
						<div>
							{{ j.content }}
							Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit possimus natus quisquam
							consequuntur aliquid amet vero at illum, magni numquam qui molestiae dolorem quo cum
							commodi, fugiat atque tempora delectus.
							Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa aliquid eaque earum
							voluptate veniam voluptatem. Illo cumque aut eveniet molestiae! Veritatis alias modi
							consequatur sed rem rerum consectetur doloribus voluptate!
						</div>
					</q-card>
				</div>
			</div>
		</div>
	</q-page>
</template>

<script>
	import
	notebooks
	from '../store/notebooks.js'
	export default {
		name: 'ContentComponent',
		setup() {
			return {
				notebooks,
			}

		},
		data() {
			return {

			}
		},
		methods: {
			returnLength() {
				alert(notebooks.data.length)
			}
		},
		computed: {
			reversedItems() {
				return [...notebooks.data].reverse();
			}
		}
	}
</script>

<style scoped>
	.h-full {
		height: 100vh;
	}

	.my-card {
		min-width: 200px;
		max-width: 250px;
		min-height: 210px;
		max-height: 260px;
		border-radius: 8px;
		margin: 10px;
		cursor: pointer;
		overflow: hidden;
		/* 隐藏溢出部分 */
		text-overflow: ellipsis;
		/* 显示省略号 */
	}
</style>