import { createRouter, createWebHistory } from 'vue-router'
import ContentBar from '../components/contentBar.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: ContentBar,
	meta: { transition: 'slide-down' }
  },
  {
    path: '/chat',
    name: 'chat',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ChatView.vue'),
	meta: { transition: 'slide-up' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
