// store.js
import {
	reactive
} from 'vue';

const chats = reactive({
	newMessage: '',
	isChating: false,
	messages: [{
			id: 1,
			content: 'Hello! How can I assist you today?',
			sender: 'bot',
			timestamp: new Date().toISOString(),
		},
		{
			id: 2,
			content: 'I have a question about Vue.',
			sender: 'user',
			timestamp: new Date().toISOString(),
		},
		{
			id: 3,
			content: 'I can help you.',
			sender: 'bot',
			timestamp: new Date().toISOString(),
		},
	],
	nextId: 4,
	addMessage(content, sender) {
		this.messages.push({
			id: this.nextId++,
			content,
			sender,
			timestamp: new Date().toISOString(),
		});
	},
});

export default chats;