// store.js
import {
	reactive
} from 'vue'


function generateColors() {
    // 生成随机颜色
    let color = Math.floor(Math.random() * 0xFFFFFF);
    
    // 生成反向背景颜色
    let bgColor = 0xFFFFFF - color;

    // 返回包含 color 和 bgColor 的对象
    return {
        color: `#${color.toString(16).padStart(6, '0')}`, // 确保有 6 位
        bgColor: `#${bgColor.toString(16).padStart(6, '0')}` // 确保有 6 位
    };
}


const store = reactive({
	data: [{
			id: 1,
			orign: 0,
			isSelected: true,
			title: 'My journey with Vue',
			content: 'My journey with Vue',
			colors: generateColors()
		},
		{
			id: 2,
			orign: 0,
			isSelected: false,
			title: 'Blogging with Vue',
			content: 'Blogging with Vue',
			colors: generateColors()
		},
		{
			id: 3,
			orign: 1,
			isSelected: true,
			title: 'Why Vue is so fun',
			content: 'Why Vue is so fun',
			colors: generateColors()
		}
	],
	addNotebook(orign,ntext) {
		let note = {
			id: this.data.length + 1,
			orign: orign,
			isSelected: false,
			title: ntext.toString(),
			content: ntext.toString(),
			colors: generateColors()
		}
		this.data.push(note)
	},
	addNotebookByClick(ntext) {
		this.addNotebook(1,ntext)
	},
	addNotebookByChat(ntext) {
		this.addNotebook(0,ntext)
	},
})

export default store;