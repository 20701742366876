// store.js
import {
	reactive
} from 'vue'

const files = reactive({
	data: [{
			id: 1,
			name:'lorem',
			type: 'word',
			size:123
		},
		{
			id: 2,
			name:'math',
			type: 'pdf',
			size:431
		},
		{
			id: 3,
			name:'pulsar',
			type: 'jpg',
			size:687
		}
	],
	addFile(file) {
		this.data.push(file)
	},
})

export default files;