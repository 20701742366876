import {
	createApp
} from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/notify/Notify.js';
import quasarUserOptions from './quasar-user-options'

import '../static/common.css';
import router from './router'
import '../static/animate.css';


createApp(App).use(router).use(Quasar, {
	plugins: {
		Notify
	},
	config: {
		notify: {
			/* look at QuasarConfOptions from the API card */ }
	}
}, quasarUserOptions).mount('#app')