<template>
	<div>
		<q-layout view="lHh LpR lFf">
			<q-header elevated>
				<q-toolbar class="bg-gradual-blue text-white">
					<q-btn dense flat round icon="menu" @click="toggleLeftDrawer" />
					<q-toolbar-title class="flex justify">
						<q-input :input-style=topSearch standout="bg-cyan light" v-model="myNotebook"
							class="text-xl m-tb-xxs" clearable>
						</q-input>
						<div>
							<q-btn flat @click="bar2 = true">
								<q-icon name="settings" class="m" size="md" />
							</q-btn>
							<q-btn flat>
								<q-icon name="share" class="m" size="md" />
							</q-btn>
							<q-btn flat>
								<q-icon name="contacts" class="m" size="md" />
							</q-btn>
						</div>
					</q-toolbar-title>
				</q-toolbar>
				<div class="bg-white flex">
					<q-btn flat @click="addNotebookByClick(1)" label="添加笔记" class="m-tb-xxs m-lr-xxs" />
					<q-btn flat @click="selectAllNotebook" label="全选" class="m-tb-xxs m-lr-xs" />
					<div v-if="isAllSelected">
						<q-btn flat @click="deleteNotebook(index)" label="删除笔记" class="m-tb-xxs m-lr-xxs" />
						<q-btn flat @click="noSelectAllNotebook" label="取消全选" class="m-tb-xxs m-lr-xs" />
					</div>
				</div>
			</q-header>

			<q-drawer show-if-above v-model="leftDrawerOpen" side="left" bordered>
				<q-scroll-area class="fit">
					<q-uploader @added="onFilesAdded" url="http://localhost:8080/" class="p-xs" flat bordered
						style="max-width: 300px" />
					<q-list>
						<q-item clickable v-ripple>
							<q-item-section avatar>
								<q-icon color="primary" name="bluetooth" />
							</q-item-section>
							<q-item-section>Icon as avatar</q-item-section>
						</q-item>

						<q-item clickable v-ripple>
							<q-item-section avatar>
								<q-avatar color="teal" text-color="white" icon="bluetooth" />
							</q-item-section>
							<q-item-section>Avatar-type icon</q-item-section>
						</q-item>

						<q-item clickable v-ripple>
							<q-item-section avatar>
								<q-avatar rounded color="purple" text-color="white" icon="bluetooth" />
							</q-item-section>
							<q-item-section>Rounded avatar-type icon</q-item-section>
						</q-item>

						<q-item clickable v-ripple>
							<q-item-section avatar>
								<q-avatar color="primary" text-color="white">
								</q-avatar>
							</q-item-section>
							<q-item-section>Letter avatar-type</q-item-section>
						</q-item>
						<q-item clickable v-ripple v-for="item,index in myfiles.data" :key="index">
							<q-item-section avatar>
								<q-icon name="image" v-if="item.type.includes('image')"></q-icon>
								<q-icon name="picture_as_pdf" v-else-if="item.type==='pdf'"></q-icon>
								<q-icon name="article" v-else-if="item.type===word"></q-icon>
								<q-icon name="source" v-else></q-icon>

							</q-item-section>
							<q-item-section style="overflow: hidden;">{{ item.name }}</q-item-section>
							<q-item-section>{{ formatSize(item.size) }}</q-item-section>
						</q-item>
					</q-list>
				</q-scroll-area>
			</q-drawer>

			<q-page-container>
				<transition :enter-active-class="enterActiveClass" :leave-active-class="leaveActiveClass">
					<router-view />
				</transition>
			</q-page-container>


			<q-footer>
				<div class="row justify-center">
					<div class="footer-container col-6">
						<div class="row justify-between">
							<nav>
								<router-link v-if="!chats.isChating" to="/chat">
									<q-btn @click="changeChat" flat style="color: #4e6ef2" label="查看聊天"
										class="text-md m-r p" />
								</router-link>
								<router-link v-else to="/">
									<q-btn @click="changeChat" flat style="color: #4e6ef2" label="关闭聊天"
										class="text-md m-r p" />
								</router-link>
							</nav>
							<div class="input-container col-7">
								<input type="text" placeholder="开始输入..." @keyup.enter="sendMessage"
									v-model="chats.newMessage" />
								<q-btn round class="submit-button" @click="sendMessage">
									<q-icon name="arrow_forward"></q-icon>
								</q-btn>
							</div>
							<q-btn class="text-md m-l p" flat style="color: #4e6ef2" label="笔记本助手" />
						</div>
					</div>
				</div>
			</q-footer>


			<q-dialog v-model="bar2" persistent transition-show="flip-down" transition-hide="flip-up">
				<q-card class="bg-primary text-white">
					<q-bar>
						<q-icon name="network_wifi" />
						<q-icon name="network_cell" />
						<q-icon name="battery_full" />
						<div>9:34</div>

						<q-space />

						<q-btn dense flat icon="close" v-close-popup>
							<q-tooltip class="bg-white text-primary">Close</q-tooltip>
						</q-btn>
					</q-bar>

					<q-card-section>
						<div class="text-h6">Alert</div>
					</q-card-section>

					<q-card-section class="q-pt-none">
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum repellendus sit voluptate
						voluptas eveniet porro. Rerum blanditiis perferendis totam, ea at omnis vel numquam
						exercitationem aut, natus minima, porro labore.
					</q-card-section>
				</q-card>
			</q-dialog>



		</q-layout>
	</div>
</template>

<script>
	import {
		ref
	} from 'vue'
	import
	notebooks
	from '../src/store/notebooks.js'
	import
	chats
	from './store/chats.js'
	import
	myfiles
	from './store/files.js'
	import {
		useQuasar
	} from 'quasar'

	export default {
		data() {
			return {
				myNotebook: 'My Notebook',
				isAllSelected: false,
				topSearch: {
					"color": "white",
					"text-align": "center"
				},
				chatBar: {
					"background-color": "#DDD",
					"height": "200px"
				},
				menuList: [{
						icon: 'inbox',
						label: 'Inbox',
						separator: true
					},
					{
						icon: 'send',
						label: 'Outbox',
						separator: false
					},
					{
						icon: 'delete',
						label: 'Trash',
						separator: false
					},
					{
						icon: 'error',
						label: 'Spam',
						separator: true
					},
					{
						icon: 'settings',
						label: 'Settings',
						separator: false
					},
					{
						icon: 'feedback',
						label: 'Send Feedback',
						separator: false
					},
					{
						icon: 'help',
						iconColor: 'primary',
						label: 'Help',
						separator: false
					}
				],

			}
		},
		setup() {
			const leftDrawerOpen = ref(false)
			const $q = useQuasar()

			return {
				notebooks,
				chats,
				myfiles,
				leftDrawerOpen,
				bar2: ref(false),
				toggleLeftDrawer() {
					leftDrawerOpen.value = !leftDrawerOpen.value
				},
				showNotif() {
					$q.notify({
						message: 'paste success',
						color: 'purple'
					})
				}
			}
		},
		computed: {
			enterActiveClass() {
				const transition = this.$route.meta.transition;
				return transition === 'slide-up' ? 'animated slideInUp' : 'animated slideInDown';
			},
			leaveActiveClass() {
				const transition = this.$route.meta.transition;
				return transition === 'slide-up' ? 'animated slideOutUp' : 'animated slideOutDown';
			}
		},
		methods: {
			changeChat() {
				chats.isChating = !chats.isChating
			},
			generateColors() {
				// 生成随机颜色
				let color = Math.floor(Math.random() * 0xFFFFFF);

				// 生成反向背景颜色
				let bgColor = 0xFFFFFF - color;

				// 返回包含 color 和 bgColor 的对象
				return {
					color: `#${color.toString(16).padStart(6, '0')}`, // 确保有 6 位
					bgColor: `#${bgColor.toString(16).padStart(6, '0')}` // 确保有 6 位
				}
			},
			addNotebook(orign) {
				let note = {
					id: notebooks.length + 1,
					orign: orign,
					isSelected: false,
					title: 'Blogging with Vue',
					content: 'Blogging with Vue',
					colors: this.generateColors()
				}
				notebooks.data.push(note)
			},
			addNotebookByClick(orign) {
				this.addNotebook(orign)
			},
			addNotebookByChat(orign) {
				this.addNotebook(orign)
				this.changeChat()
			},
			selectAllNotebook() {
				if (!this.isAllSelected) {
					notebooks.data.forEach(notebook => {
						notebook.isSelected = true;
					});
					this.isAllSelected = !this.isAllSelected
				}
			},
			noSelectAllNotebook() {
				if (this.isAllSelected) {
					notebooks.data.forEach(notebook => {
						notebook.isSelected = false;
					});
					this.isAllSelected = !this.isAllSelected
				}
			},
			deleteNotebook() {
				notebooks.data = notebooks.data.filter(notebook => !notebook.isSelected);
				this.isAllSelected = !this.isAllSelected
			},
			sendMessage() {
				if (chats.newMessage.trim()) {
					chats.addMessage(chats.newMessage, 'user');
					this.replyToMessage(chats.newMessage);
					chats.newMessage = '';
				}
			},
			replyToMessage(content) {
				setTimeout(() => {
					chats.addMessage(`你发送了: ${content}`, 'bot');
				}, 1000);
			},
			formatTimestamp(timestamp) {
				const date = new Date(timestamp);
				return date.toLocaleTimeString();
			},
			onFilesAdded(files) {
				files.forEach(file => {
					let afile = {
						id: files.length + 1,
						name: file.name,
						type: file.type,
						size: file.size,
					};
					myfiles.data.push(afile);

					console.log('文件名称:', afile.name);
					console.log('文件类型:', afile.type);
					// console.log('文件大小:', fileSize);
					console.log('文件大小:', this.formatSize(afile.size));
				});
			},
			formatSize(bytes) {
				const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
				if (bytes === 0) return '0 Byte';
				const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
				return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
			},

		},
	}
</script>

<style scoped>
	.footer-container {
		position: fixed;
		bottom: 0;
		background-color: #f0f4ff;
		padding: 10px 20px;
		box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.2);
	}



	.input-container {
		display: flex;
		align-items: center;
		background-color: #ffffff;
		border-radius: 20px;
		padding: 5px 10px;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
	}

	.input-container span {
		margin-right: 10px;
	}

	.input-container input {
		border: none;
		outline: none;
		flex: 1;
	}

	.submit-button {
		background-color: #4e6ef2;
		color: white;
		/* 		border: none;
			border-radius: 50%;
			width: 30px;
			height: 30px;
			cursor: pointer;
			margin-left: 10px; */
	}

	.help-link {
		color: #4e6ef2;
	}
</style>